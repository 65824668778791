$(document).ready(function() {
    if($( window ).width() < 746){
        $('#head .videoBg').addClass('nonMobileBg')
                           .css('height','')
                           .attr('data-vide-bg','')
                           .attr('data-vide-options','')
                           .html('');
    }else{
        obj = $('#slider .videoBg');
        console.log(obj.attr('data-video'));
        obj.append( '<video autoplay="" loop="" muted="" ><source src="'+obj.attr('data-video')+'" type="video/mp4"></video>' );
        //loadScript('/scripts/vide.js');
        //<video autoplay="" loop="" muted="" style="margin: auto; position: absolute; z-index: -1; top: 80%; left: 0%; transform: translate(0%, -80%); visibility: visible; opacity: 1; width: auto; height: 602px;"><source src="/video/video.mp4" type="video/mp4"></video>
    }

    $(function () {
        $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
    } );

    $('input').iCheck({
      checkboxClass: 'icheckbox_square-blue',
      radioClass: 'iradio_square-blue',
      increaseArea: '20%' // optional
    });

    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
    $('input[name="requestCompanyInfo"]').on('ifChecked', function(event){
        $('.interestedbox .hiden').addClass('active');
    });
    $('input[name="requestCompanyInfo"]').on('ifUnchecked', function(event){
        $('.interestedbox .hiden').removeClass('active');
    });


    $("a.mobileNav").click(function() {
        if($('#nav .nav').hasClass('active')){
            $('#nav .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-bars').removeClass('icon-times');
        }else{
            $('#nav .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-bars').addClass('icon-times');
        }
    });

    $( ".form-group input,.form-group textarea" ).focusin(function() {
        $(this).closest('.form-group').addClass('focus');
    });
    $( ".form-group input,.form-group textarea" ).focusout(function() {
        val = $(this).val();
        if(val == ''){
            $(this).closest('.form-group').removeClass('focus');
        }

    });

    //$('.lazy').show().lazy();

    /*
    $('.lazy').lazy({
            effect: "fadeIn",
            effectTime: 500,
            threshold: 0
    });*/
    var owl = $('.owl-slider');
    var owlArticle = $('.owl-article');

    owl.owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        dots: true,
        navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
        items: 1,
        autoplay:true,
        autoplayTimeout:8000
    });

    owlArticle.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        dots: false,
        navText: ['<i class="icon icon-arrow-left1"><i>','<i class="icon icon-arrow-right1"><i>'],
        items: 4,
        autoplay:false,
        responsive : {
            0 : {
                items: 1
            },
            480 : {
                items: 2
            },
            768 : {
                items: 3
            },
            1000 : {
                items: 4
            }
        }
    });



    $(".jsLink,.article").click(function(){
         window.location=$(this).find("a").attr("href");
         return false;
    });

});
